import { MessageSquare, Shield, Smile } from 'react-feather';
import { Typography, styled } from '@mui/material';

import { Button } from '@cognassist/react-components';
import { pxToRem } from '../../../utils/style-functions';
import { useCommunityState } from '../../../custom-providers/CommunityProvider';

const ButtonWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    borderTop: `1px solid ${theme.palette.grey[100]}`,
    margin: theme.spacing(0, -3),
    padding: theme.spacing(2, 3, 2, 0),
}));

const GuidelinesWrapper = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
}));

const Section = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(3),
}));

const SectionHeadingWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    marginBottom: theme.spacing(1),
    alignItems: 'center',
}));

const SectionHeading = styled(Typography)(({ theme }) => ({
    fontWeight: 500,
    size: pxToRem(18),
    lineHeight: pxToRem(27),
    marginLeft: theme.spacing(1),
}));

const Guidelines: React.FC = () => {
    const { dispatch } = useCommunityState();

    return (
        <>
            <Typography
                sx={{ fontSize: 24, lineHeight: pxToRem(36), mb: 3 }}
                variant='h1'
            >
                Community Guidelines
            </Typography>
            <GuidelinesWrapper>
                <Section>
                    <SectionHeadingWrapper>
                        <Smile size={24} />
                        <SectionHeading>Be Respectful</SectionHeading>
                    </SectionHeadingWrapper>
                    <Typography>
                        We&apos;re all here to support and help each other,
                        being empathetic and understanding is the best way to
                        show respect.
                    </Typography>
                </Section>

                <Section>
                    <SectionHeadingWrapper>
                        <MessageSquare size={24} />

                        <SectionHeading>Stay On Topic</SectionHeading>
                    </SectionHeadingWrapper>
                    <Typography>
                        The Cognassist community is designed to be a safe space
                        for all things neuro-inclusion. So let&apos;s keep
                        discussions on topic, but have fun too!
                    </Typography>
                </Section>

                <Section>
                    <SectionHeadingWrapper>
                        <Shield size={24} />
                        <SectionHeading>Maintain Privacy</SectionHeading>
                    </SectionHeadingWrapper>
                    <Typography>
                        Not everyone is comfortable discussing their
                        neuro-differences, so let&apos;s honour their wishes and
                        avoid sharing personal details that could identify
                        someone.
                    </Typography>
                </Section>
            </GuidelinesWrapper>
            <ButtonWrapper>
                <Button
                    color='inherit'
                    text='Back'
                    sx={{ mr: 2 }}
                    onClick={() => {
                        dispatch({
                            type: 'SET_ONBOARDING_PAGE',
                            payload: { onboardingPage: 1 },
                        });
                    }}
                />
                <Button
                    text='Continue'
                    onClick={() => {
                        dispatch({
                            type: 'SET_ONBOARDING_PAGE',
                            payload: { onboardingPage: 4 },
                        });
                    }}
                />
            </ButtonWrapper>
        </>
    );
};

export default Guidelines;
