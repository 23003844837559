import React, { useContext, useMemo } from 'react';

import { useImmerReducer } from 'use-immer';

interface ICommunityContext {
    state: State;
    dispatch: React.Dispatch<Action>;
}

type State = {
    onboardingPage: number;
    apiKey: string | null;
    accessToken: string | null;
    communityDisplayName: string | null;
};

export const initialState = {
    onboardingPage: 1,
    apiKey: null,
    accessToken: null,
    communityDisplayName: null,
};

type Action =
    | {
          type: 'SET_ONBOARDING_PAGE';
          payload: { onboardingPage: number };
      }
    | {
          type: 'SET_COMMUNITY_DISPLAY_NAME';
          payload: { communityDisplayName: string | null };
      }
    | {
          type: 'SET_KEY_AND_TOKEN';
          payload: { apiKey: string | null; accessToken: string | null };
      };

export const reducer = (state: State, action: Action): void => {
    switch (action.type) {
        case 'SET_ONBOARDING_PAGE':
            state.onboardingPage = action.payload.onboardingPage;
            break;
        case 'SET_COMMUNITY_DISPLAY_NAME':
            state.communityDisplayName = action.payload.communityDisplayName;
            break;
        case 'SET_KEY_AND_TOKEN':
            state.apiKey = action.payload.apiKey;
            state.accessToken = action.payload.accessToken;
            break;
    }
};

export const CommunityContext = React.createContext<ICommunityContext>({
    state: initialState,
    dispatch: () => false,
});

interface IOwnProps {
    children: React.ReactNode;
}

export const CommunityProvider: React.FC<IOwnProps> = ({ children }) => {
    const [state, dispatch] = useImmerReducer<State, Action>(
        reducer,
        initialState
    );

    const CommunityContextMemo = useMemo(() => {
        return {
            state,
            dispatch,
        };
    }, [state, dispatch]);

    return (
        <CommunityContext.Provider value={CommunityContextMemo}>
            {children}
        </CommunityContext.Provider>
    );
};

export const useCommunityState = (): ICommunityContext =>
    useContext(CommunityContext);
