import { IconButton as MuiIconButton, styled } from '@mui/material';

import { X } from 'react-feather';
import { shouldForwardProps } from '../utils/shouldForwardProp';

export const Wrapper = styled('div', {
    ...shouldForwardProps('isOpen'),
})<{ isOpen?: boolean }>(({ isOpen, theme }) => ({
    maxWidth: 444,
    width: '100%',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    boxShadow: theme.cogShadows.lg,
    overflowY: 'auto',
    flexFlow: 'column nowrap',
    padding: theme.spacing(3),
    transform: isOpen ? 'translateX(0)' : 'translateX(444px)',
    background: theme.palette.common.white,
    zIndex: 100,
    transition: 'transform 0.3s ease',
    height: '100vh',
    borderLeft: `1px solid ${theme.palette.grey[100]}`,
    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(3, 4, 8, 3),
        maxWidth: '100vw',
        transform: isOpen
            ? 'translateX(0) translateY(62px)'
            : 'translateX(100vw) translateY(62px)',
    },
}));

const TopWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
}));

const CloseIcon = styled(X)(() => ({
    cursor: 'pointer',
}));

const IconButton = styled(MuiIconButton)(({ theme }) => ({
    background: theme.palette.grey[50],
    padding: theme.spacing(0.5),
    marginLeft: theme.spacing(2),
}));

interface IOwnProps {
    close: () => void;
    children: React.ReactNode;
    id: string;
    open: boolean;
    TitleWrapper?: React.ReactElement;
}

export const Drawer: React.FC<IOwnProps> = ({
    close,
    children,
    id,
    open,
    TitleWrapper,
}) => {
    return (
        <Wrapper id={id} isOpen={open}>
            <TopWrapper>
                {TitleWrapper ?? null}
                <IconButton onClick={close} aria-label='close-drawer'>
                    <CloseIcon />
                </IconButton>
            </TopWrapper>
            {children}
        </Wrapper>
    );
};
