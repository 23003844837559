import { styled } from '@mui/material';

export const LoadingWrapper = styled('div')(({ theme }) => ({
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: 'calc(100vh - 16px)',
    [theme.breakpoints.down(744)]: {
        minHeight: 'calc(100vh - 142px)',
    },
}));
