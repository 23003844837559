import { Button } from '@cognassist/react-components';
import IconCognasssitant from '../../../assets/img/icons/caicon.svg';
import React from 'react';
import { endpoints } from '../../../api/endpoints';
import { styled } from '@mui/material';
import { useAuth } from '../../../custom-providers/AuthProvider';

const StrategiesButton = styled(Button)(({ theme }) => ({
    marginTop: theme.spacing(3),
}));

interface IOwnProps {
    setRoleDrawerOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CaiStrategyButton: React.FC<IOwnProps> = ({
    setRoleDrawerOpen,
}) => {
    const { getUserJobRole } = endpoints();

    const {
        state: {
            userConfig: { userId },
        },
    } = useAuth();

    const getJobRole = async (id: string) => {
        const { data } = await getUserJobRole(id);

        if (data) {
            return data;
        }

        return;
    };

    const strategyHandler = async (
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>
    ) => {
        e.stopPropagation();

        if (userId) {
            const data = await getJobRole(userId);

            if (data) {
                if (!data.jobRole) {
                    //TODO NEGATE THIS
                    setRoleDrawerOpen(true);
                }
            }
        }
    };

    return (
        <StrategiesButton
            text='How do I apply this?'
            fullWidth
            onClick={(e) => strategyHandler(e)}
            startIcon={<img src={IconCognasssitant} alt='' />}
        />
    );
};
