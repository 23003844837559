import { Button, Input } from '@cognassist/react-components';
import { Controller, useForm } from 'react-hook-form';
import { InputLabel, Typography, styled } from '@mui/material';

import { Drawer } from '../../Drawer';
import { ErrorMessage } from '@hookform/error-message';
import { ErrorText } from '../../styled-components';
import { endpoints } from '../../../api/endpoints';
import { pxToRem } from '../../../utils/style-functions';
import { useAuth } from '../../../custom-providers/AuthProvider';
import { useSnackbar } from 'notistack';

const TitleWrapper = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: theme.spacing(2),
    width: '100%',
}));

const Title = styled(Typography)(() => ({
    fontSize: pxToRem(24),
    fontWeight: 500,
    lineHeight: pxToRem(36),
    width: '100%',
}));

const InputWrapper = styled('div')(({ theme }) => ({
    marginBottom: theme.spacing(3),
}));

const Label = styled(InputLabel)(({ theme }) => ({
    fontSize: pxToRem(16),
    lineHeight: pxToRem(24),
    color: theme.palette.text.primary,
    fontWeight: 600,
    marginBottom: theme.spacing(2),
}));

interface IOwnProps {
    close: () => void;
    open: boolean;
}

interface IFormFields {
    jobRole: string;
}

export const RoleDrawer: React.FC<IOwnProps> = ({ close, open }) => {
    const { setUserJobRole } = endpoints();
    const { enqueueSnackbar } = useSnackbar();

    const {
        control,
        formState: { errors },
        handleSubmit,
    } = useForm<IFormFields>({
        defaultValues: {
            jobRole: '',
        },
    });

    const {
        state: {
            userConfig: { userId },
        },
    } = useAuth();

    const handleJobRole = async (fieldValues: IFormFields) => {
        if (userId) {
            const { error } = await setUserJobRole(userId, {
                ...fieldValues,
            });

            if (!error) {
                enqueueSnackbar('Job role updated', {
                    variant: 'success',
                });
            }
        }
    };

    return (
        <Drawer
            id='role-drawer'
            open={open}
            close={close}
            TitleWrapper={
                <TitleWrapper>
                    <Title variant='h1'>
                        This is the first time we&apos;ve done this
                    </Title>
                </TitleWrapper>
            }
        >
            <Typography sx={{ mb: 3 }}>
                We need to capture your job role so that we can correctly apply
                context to your strategy. Don&apos;t worry, you&apos;ll only
                need to do this once!
            </Typography>

            <InputWrapper>
                <Label error={!!errors.jobRole} id='job-role-label'>
                    Job role
                </Label>
                <Controller
                    control={control}
                    name='jobRole'
                    rules={{
                        required: 'A job role must be provided',
                        maxLength: {
                            value: 230,
                            message:
                                'You have gone over the 230 character limit for this field, please reduce it and try again.',
                        },
                    }}
                    render={({ field }) => (
                        <Input
                            {...field}
                            inputProps={{
                                'aria-labelledby': 'job-role-label',
                            }}
                            fullWidth
                            hasError={!!errors.jobRole}
                        />
                    )}
                />
                <ErrorMessage
                    errors={errors}
                    name='jobRole'
                    render={({ message }) => <ErrorText>{message}</ErrorText>}
                />
            </InputWrapper>

            <Button
                text='Save'
                fullWidth
                onClick={handleSubmit(handleJobRole)}
            />
        </Drawer>
    );
};
